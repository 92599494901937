import React, { useMemo } from "react";
import { FEE_COLUMNS } from "../constants/fees.constants";
import { getFormattedDate } from "../../../../utils/date";

const fees = [
  {
    id: 1,
    paymentId: "7263758236",
    status: "WAITING",
    createdAt: getFormattedDate("2024-10-01T14:34:07.107Z"),
    updatedAt: getFormattedDate("2024-10-02T14:34:07.107Z"),
  },
  {
    id: 2,
    paymentId: "234141411241",
    status: "NO_CHANGES",
    createdAt: getFormattedDate("2024-10-02T14:34:07.107Z"),
    updatedAt: getFormattedDate("2024-10-03T14:34:07.107Z"),
  },
];

const useFees = () => {
  // const [fees = [], isLoading, hasError, getFees] = useData({
  //     endpoint: "fee",
  //   });

  const columns = FEE_COLUMNS;

  const Content = useMemo(() => {
    return (
      <table id="example4" className="display dataTable no-footer w-100">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fees.map((fee, index) => (
            <tr key={index}>
              <td>{fee.paymentId}</td>
              <td>{fee.status}</td>
              <td>{fee.createdAt}</td>
              <td>{fee.updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [fees]);

  return {
    Content,
  };
};

export default useFees;
