import { useState } from "react";
import { handleUpdateBusinessPaymentInfo } from "../../../actions/business";

function validateIban(val) {
  const ibanRegex = /^TR\d{24}$/;

  var status = true;

  if (!ibanRegex.test(val)) {
    alert("Please enter valid iban.");

    status = false;
  }

  return status;
}

const getFormValues = (paymentInfo) => {
  return paymentInfo
    ? {
        fullName: paymentInfo.fullName,
        address: paymentInfo.address,
        iban: paymentInfo.iban,
        tax: paymentInfo.tax,
      }
    : {
        fullName: "",
        address: "",
        iban: "",
        tax: "",
      };
};

const validateForm = (values, setErrors) => {
  let hasError = false;
  const errors = {};

  if (!validateIban(values.iban)) {
    errors.iban = "Invalid IBAN format";
    hasError = true;
  }

  if (!values.fullName) {
    errors.fullName = "Full name is required";
    hasError = true;
  }

  if (!values.address) {
    errors.address = "Address is required";
    hasError = true;
  }

  if (!values.tax) {
    errors.tax = "Tax information is required";
    hasError = true;
  }

  setErrors(errors);
  return hasError;
};

const usePaymentInfo = ({ business, onBusinessUpdated }) => {
  const formValues = getFormValues(business.paymentInfo);

  const [values, setValues] = useState(formValues);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm(values, setErrors)) {
      return null;
    }

    const variables = {
      fullName: values.fullName,
      address: values.address,
      iban: values.iban,
      tax: values.tax,
    };

    handleUpdateBusinessPaymentInfo({
      variables,
      onSuccess: onBusinessUpdated,
    });
  };

  return {
    values,
    errors,
    handleChangeInput,
    handleSubmit,
    isDisabled,
    setIsDisabled,
  };
};

export default usePaymentInfo;
