import React from "react";
import { IconButton } from "rsuite";
import Select from "react-select";
import { getLanguageObject } from "../../../../../utils/language";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";
import useBusinessInfo from "./hooks/useBusinessInfo";
import FileUploader from "../../../../../components/fileUploader/FileUploader";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

const BusinessInfo = ({ business, onBusinessUpdated }) => {
  const {
    values,
    types,
    tags,
    isDisabled,
    handleChangeInput,
    handleSubmit,
    setIsDisabled,
    businessLinks,
    handleDeleteBusinessLinkInput,
    handleChangeBusinessLink,
    handleAddBusinessLinkInput,
  } = useBusinessInfo({
    business,
    onBusinessUpdated,
  });

  const fileUploaderUrlList = values?.logo
    ? [
        {
          url: values.logo,
        },
      ]
    : [];

  return (
    <>
      <div className="pt-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.about.title}
          </h4>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.name}
                </label>
                <input
                  type="text"
                  disabled={isDisabled}
                  placeholder="Name"
                  className="form-control"
                  onChange={(e) => handleChangeInput("name", e.target.value)}
                  value={values.name}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.description}
                </label>
                <input
                  type="text"
                  disabled={isDisabled}
                  placeholder="Description"
                  className="form-control"
                  onChange={(e) =>
                    handleChangeInput("description", e.target.value)
                  }
                  value={values.description}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.type}
                </label>
                <Select
                  isDisabled={isDisabled}
                  isMulti
                  options={types}
                  isSearchable={false}
                  onChange={(e) => handleChangeInput("types", e)}
                  value={values.types}
                  className="custom-react-select"
                />
              </div>

              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.logo}
                </label>
                <FileUploader
                  business={business}
                  fileList={fileUploaderUrlList}
                  isDisabled={isDisabled}
                  multiple={false}
                  autoUpload={true}
                  onError={(error) => {
                    console.log("error", error);
                  }}
                  onChange={(files = []) => {
                    if (!files.length) {
                      handleChangeInput("logo", "");
                    }
                  }}
                  onSuccess={(response) => {
                    handleChangeInput("logo", response.location);
                  }}
                >
                  <button>
                    <AvatarIcon />
                  </button>
                </FileUploader>
              </div>
            </div>
            {isDisabled ? (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => setIsDisabled(false)}
              >
                {getLanguageObject().pages.common.form.edit_button}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => handleSubmit()}
              >
                {getLanguageObject().pages.common.form.submit_button}
              </button>
            )}
          </form>
        </div>
      </div>

      <div className="profile-personal-info mt-5">
        <h4 className="text-primary mb-4">
          {getLanguageObject().pages.business_profile.info.title}
        </h4>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.address}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            <span>{business?.info?.address || "-"}</span>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.employees}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            <p>{business?.employees?.length}</p>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.links}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            {businessLinks.map((item, index) => (
              <div style={{ flexGrow: 1, mt: 1 }} key={index}>
                <div className="row">
                  <div className="col-3 mb-3">
                    <div>
                      <label id="demo-simple-select-label">
                        {
                          getLanguageObject().pages.business_profile.info
                            .link_name
                        }
                      </label>
                      <Select
                        options={tags}
                        isMulti={false}
                        name="name"
                        isDisabled={index !== businessLinks.length - 1}
                        labelId="demo-simple-select-label"
                        value={item?.name}
                        label="name"
                        onChange={(event) =>
                          handleChangeBusinessLink("name", event, index)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <label htmlFor="outlined-adornment-socialMediaLinks">
                        {getLanguageObject().pages.business_profile.info.url}
                      </label>
                      <input
                        id="outlined-adornment-socialMediaLinks"
                        type="text"
                        className="form-control"
                        value={item.url}
                        name="url"
                        disabled={index !== businessLinks.length - 1}
                        onChange={(event) =>
                          handleChangeBusinessLink(
                            "url",
                            event.target.value,
                            index,
                          )
                        }
                        label="Url"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        marginTop: 20,
                      }}
                    >
                      {businessLinks.length > 1 && (
                        <div>
                          <IconButton
                            icon={<MinusIcon />}
                            appearance="default"
                            onClick={() =>
                              handleDeleteBusinessLinkInput(index, item.id)
                            }
                          />
                        </div>
                      )}
                      {index === businessLinks.length - 1 && (
                        <div>
                          <IconButton
                            icon={<PlusIcon />}
                            appearance="default"
                            onClick={() => handleAddBusinessLinkInput()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
