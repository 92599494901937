import React from "react";
import PackageLessons from "../package_lessons/PackageLessons";
import Memberships from "../memberships/Memberships";
import { Nav, Tab } from "react-bootstrap";
import { getLanguageObject } from "../../../utils/language";

const Services = () => {
  return (
    <div className="profile-tab">
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="Membership">
          <Nav as="ul" className="nav nav-tabs">
            <Nav.Item as="li" className="nav-item">
              <Nav.Link to="#membership" eventKey="Membership">
                {getLanguageObject().pages.memberships.title}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" className="nav-item">
              <Nav.Link to="#package" eventKey="Package">
                {getLanguageObject().pages.package_lessons.title}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3" >
            <Tab.Pane id="membership" eventKey="Membership">
              <Memberships />
            </Tab.Pane>

            <Tab.Pane id="package" eventKey="Package">
              <PackageLessons />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Services;
