import React from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import { getLanguageObject } from "../../../../../utils/language";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { UploadButton } from "./BusinessImages.styled";
import useBusinessImages from "./hooks/useBusinessImages";
import FileUploader from "../../../../../components/fileUploader/FileUploader";

const BusinessImages = ({ business, onBusinessUpdated, onInit }) => {
  const {
    businessImages,
    handleSubmit,
    handleChangeImages,
    handleRemoveImage,
  } = useBusinessImages({
    business,
    onBusinessUpdated,
  });

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h5 className="text-primary ">
            {getLanguageObject().pages.business_profile.business_images.title}
          </h5>

          <div className="d-flex align-items-center justify-content-center gap-4">
            <FileUploader
              business={business}
              multiple={true}
              autoUpload={true}
              onSuccess={(response) => {
                console.log("RES:", response);

                handleChangeImages(response);
              }}
              onError={(error) => {
                console.log("onError", error);
              }}
            >
              <button style={UploadButton}>
                <FileUploadIcon className="fs-3" />
              </button>
            </FileUploader>

            <button className="btn btn-primary" onClick={handleSubmit}>
              {
                getLanguageObject().pages.business_profile.business_images
                  .upload_button
              }
            </button>
          </div>
        </div>
        <div className="card-body pt-3">
          <div className="profile-interest ">
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
              elementClassNames="row sp4"
            >
              {businessImages?.map((item, index) => (
                <div
                  data-src={item}
                  className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
                  key={index}
                >
                  <button
                    className="btn btn-danger light btn-sm"
                    onClick={(event) => {
                      event.stopPropagation();

                      handleRemoveImage(item);
                    }}
                  >
                    -
                  </button>
                  <img src={item} style={{ width: "100%" }} alt="gallery" />
                </div>
              ))}
            </LightGallery>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessImages;
