import React, { useMemo } from "react";
import { PURCHASE_COLUMNS } from "../constants/purchases.constants";
import { getFormattedDate } from "../../../../utils/date";

const purchases = [
  {
    id: 1,
    trainee: {
      user: {
        profile: {
          firstName: "FirstName",
          lastName: "LastName",
        },
      },
    },
    createdAt: getFormattedDate("2024-10-01T14:34:07.107Z"),
    updatedAt: getFormattedDate("2024-10-02T14:34:07.107Z"),
    sources: [
      {
        price: 1000,
        title: "Title",
        description: "Description",
        type: "MEMBERSHIP",
        status: "NO_CHANGES",
      },
    ],
  },
  {
    id: 2,
    trainee: {
      user: {
        profile: {
          firstName: "FirstName 2",
          lastName: "LastName 2",
        },
      },
    },
    createdAt: getFormattedDate("2024-10-01T14:34:07.107Z"),
    updatedAt: getFormattedDate("2024-10-02T14:34:07.107Z"),
    sources: [
      {
        price: 10000,
        title: "Title 2",
        description: "Description 2",
        type: "MEMBERSHIP",
        status: "NO_CHANGES",
      },
    ],
  },
];

const usePurchases = () => {
  // const [purchases = [], isLoading, hasError, getPurchase] = useData({
  //     endpoint: "purchase",
  //   });

  const columns = PURCHASE_COLUMNS;

  const Content = useMemo(() => {
    return (
      <table id="example4" className="display dataTable no-footer w-100">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase, index) => (
            <tr key={index}>
              <td>{purchase.trainee.user.profile.firstName}</td>
              <td>{purchase.trainee.user.profile.lastName}</td>

              {purchase.sources.map((soruce) => {
                return (
                  <>
                    <td>{soruce.title}</td>
                    <td>{soruce.description}</td>
                    <td>{soruce.status}</td>
                    <td>{soruce.type}</td>
                    <td>{soruce.price}</td>
                  </>
                );
              })}
              <td>{purchase.createdAt}</td>
              <td>{purchase.updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [purchases]);

  return {
    Content,
  };
};

export default usePurchases;
