import React from "react";
import BranchSelection from "../../../../../../components/branchSelection/BranchSelection";
import useMembershipForm from "./hooks/useMembershipForm";
import LevelSelection from "../../../../../../components/levelSelection/LevelSelection";
import { getLanguageObject } from "../../../../../../utils/language";
import PlatformSelection from "../../../../../../components/platformSelection/PlatformSelection";
import { platformSelectionContainer } from "./MembershipForm.styled";

const MembershipForm = ({
  showSubmitMembershipModal,
  membershipId,
  onClose,
  getMemberships,
}) => {
  const {
    handleSubmit,
    handleChangeInput,
    handleSelectBranch,
    handleSelectLevel,
    values,
    handleChangeEquipments,
    handleSelectPlatform,
    buttonLabel,
  } = useMembershipForm({
    membershipId,
    showSubmitMembershipModal,
    onClose,
    getMemberships,
  });

  return (
    <form className="comment-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="branch" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_branch}
              <span className="required">*</span>
            </label>
            <BranchSelection
              defaultBranchId={values.branchId}
              onSelectBranch={(selectedBranchId) => {
                handleSelectBranch(selectedBranchId);
              }}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="name" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_name}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={values.name}
              onChange={(event) =>
                handleChangeInput("name", event.target.value)
              }
              placeholder={`${getLanguageObject().pages.memberships.form.course_name_placeholder}`}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="description" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_description}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={values.description}
              onChange={(event) =>
                handleChangeInput("description", event.target.value)
              }
              placeholder={`${getLanguageObject().pages.memberships.form.course_description_placeholder}`}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="preparation" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_preparation}
            </label>
            <input
              type="text"
              className="form-control"
              value={values.preparation}
              onChange={(event) =>
                handleChangeInput("preparation", event.target.value)
              }
              placeholder={`${getLanguageObject().pages.memberships.form.course_preparation_placeholder}`}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="duration" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_duration} (
              {getLanguageObject().pages.memberships.form.course_duration_month}
              )<span className="required">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={values.duration}
              onChange={(event) =>
                handleChangeInput("duration", event.target.value)
              }
              placeholder={`${getLanguageObject().pages.memberships.form.course_duration_placeholder}`}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="price" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_price}
              <span className="required">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={values.price}
              onChange={(event) =>
                handleChangeInput("price", event.target.value)
              }
              placeholder={`${getLanguageObject().pages.memberships.form.course_price_placeholder}`}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="branch" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_level}
              <span className="required">*</span>
            </label>
            <LevelSelection
              defaultLevel={values.level}
              onSelectLevel={(selectedLevel) => {
                handleSelectLevel(selectedLevel);
              }}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="equipment" className="font-w600">
              {getLanguageObject().pages.memberships.form.course_equirements}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              onChange={(event) => handleChangeEquipments(event.target.value)}
              placeholder={`${getLanguageObject().pages.memberships.form.course_equirements_placeholder}`}
              required={true}
              aria-describedby="equipmentsHelpBlock"
              value={values.equipments.join(",")}
            />
            <small id="equipmentsHelpBlock" class="form-text text-muted">
              {getLanguageObject().pages.memberships.form.equipmentsHelpMessage}
            </small>
          </div>

          <div
            style={{
              gap: 5,
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            {values.equipments?.map((equipment) => (
              <span className={`badge badge-success`}>{equipment}</span>
            ))}
          </div>
        </div>

        <div className="col-lg-6" style={platformSelectionContainer.container}>
          <div style={platformSelectionContainer.content}>
            <PlatformSelection
              values={values}
              onSelectPlatform={(platform) => {
                handleSelectPlatform(platform);
              }}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group mb-3">
            <input
              type="submit"
              value={buttonLabel}
              className="submit btn btn-primary"
              name="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default MembershipForm;
