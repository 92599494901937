import React from "react";
import usePaymentInfo from "./hooks/usePaymentInfo";
import { getLanguageObject } from "../../../../../utils/language";
import { PatternFormat } from "react-number-format";

const PaymentInfo = ({ business, onBusinessUpdated }) => {
  const { handleChangeInput, values, handleSubmit, isDisabled, setIsDisabled } =
    usePaymentInfo({ business, onBusinessUpdated });

  return (
    <div className="pt-3">
      <div className="settings-form">
        <h4 className="text-primary">
          {getLanguageObject().pages.business_profile.payment_info.title}
        </h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                {
                  getLanguageObject().pages.business_profile.payment_info
                    .fullname
                }
              </label>
              <input
                type="text"
                disabled={isDisabled}
                placeholder="Fullname"
                className="form-control"
                value={values.fullName}
                onChange={(e) => handleChangeInput("fullName", e.target.value)}
              />
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                {getLanguageObject().pages.business_profile.payment_info.iban}
              </label>

              <PatternFormat
                disabled={isDisabled}
                type="text"
                format="TR########################"
                mask="_"
                className="form-control"
                allowEmptyFormatting={true}
                value={values.iban}
                onChange={(e) => handleChangeInput("iban", e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="form-label">
              {getLanguageObject().pages.business_profile.payment_info.address}
            </label>
            <input
              type="text"
              disabled={isDisabled}
              placeholder="1234 Main St"
              className="form-control"
              value={values.address}
              onChange={(e) => handleChangeInput("address", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label className="form-label">
              {getLanguageObject().pages.business_profile.payment_info.tax}
            </label>
            <input
              type="text"
              disabled={isDisabled}
              placeholder="Tax"
              className="form-control"
              value={values.tax}
              onChange={(e) => handleChangeInput("tax", e.target.value)}
            />
          </div>

          {isDisabled ? (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => setIsDisabled(false)}
            >
              {getLanguageObject().pages.common.form.edit_button}
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubmit}
            >
              {getLanguageObject().pages.common.form.submit_button}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default PaymentInfo;
