import React from "react";
import { getLanguageObject } from "../../../../../../utils/language";
import useEmployeeForm from "./hooks/useEmployeeForm";
import { PatternFormat } from "react-number-format";
import FileUploader from "../../../../../../components/fileUploader/FileUploader";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { Button } from "rsuite";

const EmployeeForm = ({
  showSubmitEmployeeModal,
  employeeId,
  onClose,
  getEmployees,
}) => {
  const {
    handleSubmit,
    handleChangeInput,
    values,
    buttonLabel,
    fileUploaderUrlList,
  } = useEmployeeForm({
    employeeId,
    showSubmitEmployeeModal,
    onClose,
    getEmployees,
  });

  return (
    <form className="employee-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="email" className="font-w600">
              {getLanguageObject().pages.employees.form.email}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              disabled={employeeId}
              value={values.email}
              onChange={(event) =>
                handleChangeInput("email", event.target.value)
              }
              placeholder={getLanguageObject().pages.employees.form.email}
              required={true}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="firstName" className="font-w600">
              {getLanguageObject().pages.employees.form.firstName}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={values.firstName}
              onChange={(event) =>
                handleChangeInput("firstName", event.target.value)
              }
              placeholder={getLanguageObject().pages.employees.form.firstName}
              required={true}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="lastName" className="font-w600">
              {getLanguageObject().pages.employees.form.lastName}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={values.lastName}
              onChange={(event) =>
                handleChangeInput("lastName", event.target.value)
              }
              placeholder={getLanguageObject().pages.employees.form.lastName}
              required={true}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <label htmlFor="phone" className="font-w600">
            {getLanguageObject().pages.employees.form.phone}
            <span className="required">*</span>
          </label>
          <PatternFormat
            format="+90##########"
            mask="_"
            className="form-control"
            isNumericString={true}
            allowEmptyFormatting={true}
            value={values.phone}
            onChange={(event) => handleChangeInput("phone", event.target.value)}
          />
        </div>

        {!employeeId && (
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label htmlFor="password" className="font-w600">
                {getLanguageObject().pages.employees.form.password}
                <span className="required">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                value={values.password}
                onChange={(event) =>
                  handleChangeInput("password", event.target.value)
                }
                placeholder={getLanguageObject().pages.employees.form.password}
                required={true}
              />
            </div>
          </div>
        )}

        {employeeId && (
          <>
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label htmlFor="address" className="font-w600">
                  {getLanguageObject().pages.employees.form.address}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.address}
                  onChange={(event) =>
                    handleChangeInput("address", event.target.value)
                  }
                  placeholder={getLanguageObject().pages.employees.form.address}
                  required={true}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label htmlFor="avatar" className="font-w600">
                  {getLanguageObject().pages.employees.form.avatar}
                </label>

                <FileUploader
                  multiple={false}
                  autoUpload={true}
                  fileList={fileUploaderUrlList}
                  onError={(error) => {
                    console.log("error", error);
                  }}
                  onChange={(files = []) => {
                    if (!files.length) {
                      handleChangeInput("avatar", null);
                    }
                  }}
                  onSuccess={(response) => {
                    handleChangeInput("avatar", response.location);
                  }}
                >
                  <Button>
                    <AvatarIcon />
                  </Button>
                </FileUploader>
              </div>
            </div>
          </>
        )}

        <div className="col-lg-12">
          <div className="form-group mb-3">
            <input
              type="submit"
              value={buttonLabel}
              className="submit btn btn-primary"
              name="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default EmployeeForm;
