import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createEmployee,
  getEmployee,
  updateEmployee,
} from "../../../../../../../../services/modules/EmployeeService";
import { useData } from "../../../../../../../hooks/useData";
import { getLanguageObject } from "../../../../../../../utils/language";

const useEmployeeForm = ({
  employeeId,
  showSubmitEmployeeModal,
  onClose,
  getEmployees,
}) => {
  const [employee] = useData({
    fetchOnce: false,
    endpoint: `employee/${employeeId}`,
  });

  const handleGetEmployee = () => {
    getEmployee(employeeId).then((response) => {
      if (response.status === 200) {
        setValues({
          email: response.data.email,
          firstName: response.data.profile.firstName,
          lastName: response.data.profile.lastName,
          phone: response.data.profile.phone,
          avatar: response.data.profile.avatar,
          address: response.data.profile.address,
        });
      }
    });
  };

  const getFormValues = (employee) => {
    return employee
      ? {
          email: employee.email,
          firstName: employee.profile.firstName,
          lastName: employee.profile.lastName,
          phone: employee.profile.phone,
          avatar: employee.profile.avatar,
          address: employee.profile.address,
        }
      : {
          email: "",
          firstName: "",
          lastName: "",
          phone: "",
          password: "",
          avatar: "",
          address: "",
        };
  };

  const formValues = getFormValues(employee);

  const [values, setValues] = useState(formValues);

  const shouldFetchEmployeeData = showSubmitEmployeeModal && employeeId;

  useEffect(() => {
    if (shouldFetchEmployeeData) {
      handleGetEmployee();
      setValues(formValues);
    }
  }, [shouldFetchEmployeeData]);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const notifyError = (text) => {
    toast.error(`❌ ${text} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isNewEmployee = !employeeId;

    const variables = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      password: values.password,
    };

    const updateVariables = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      avatar: values.avatar,
      address: values.address,
    };

    if (isNewEmployee) {
      createEmployee(variables).then((response) => {
        if (response.error) {
          notifyError(response.message);
        } else {
          onClose();
          getEmployees();
        }
      });
    } else {
      updateEmployee(employeeId, updateVariables).then((response) => {
        if (response.error) {
          notifyError(response.message);
        } else {
          onClose();
          getEmployees();
        }
      });
    }
  };

  const buttonLabel = getLanguageObject().pages.common.form.submit_button;

  const fileUploaderUrlList = values?.avatar
    ? [
        {
          url: values.avatar,
        },
      ]
    : [];

  return {
    handleSubmit,
    handleChangeInput,
    values,
    buttonLabel,
    fileUploaderUrlList,
  };
};

export default useEmployeeForm;
