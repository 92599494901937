import { useState } from "react";
import {
  createBusinessInfoLink,
  deleteBusinessInfoLink,
  handleUpdateBusiness,
} from "../../../actions/business";

const useBusinessInfo = ({ business, onBusinessUpdated }) => {
  const formValues = business
    ? {
        name: business.name,
        description: business.description,
        types: business.types.map((type) => {
          return {
            label: type,
            value: type,
          };
        }),
        logo: business.logo,
      }
    : {
        name: "",
        description: "",
        types: [],
        logo: "",
      };

  const [values, setValues] = useState(formValues);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleSubmit = () => {
    setIsDisabled(false);

    const variables = {
      name: values.name,
      description: values.description,
      types: values.types.map((type) => type.value),
      logo: values.logo,
    };

    handleUpdateBusiness({ variables, onSuccess: onBusinessUpdated });
  };

  const handleCreateBusinessLink = () => {
    const lastLink = businessLinks[businessLinks.length - 1];

    const variables = {
      name: lastLink.name.value,
      url: lastLink.url,
    };

    createBusinessInfoLink(variables);
  };

  const handleDeleteBusinessLink = (id) => {
    const variables = {
      id,
    };

    deleteBusinessInfoLink(variables);
  };

  const getBusinessLinkValues = (links) => {
    return links.length
      ? links.map((link) => {
          return {
            name: { label: link.name, value: link.name },
            url: link.url,
            id: link.id,
          };
        })
      : [
          {
            name: { label: "", value: "" },
            url: "",
            id: "",
          },
        ];
  };

  const businessLinkValues = getBusinessLinkValues(business?.info?.links);

  const [businessLinks, setBusinessLinks] = useState(businessLinkValues);

  const handleAddBusinessLinkInput = () => {
    setBusinessLinks([...businessLinks, { name: "", url: "" }]);
    const lastLink = businessLinks[businessLinks.length - 1];

    if (lastLink) {
      handleCreateBusinessLink();
    }
  };

  const handleChangeBusinessLink = (name, event, index) => {
    let onChangeValue = [...businessLinks];
    onChangeValue[index][name] = event;
    setBusinessLinks(onChangeValue);
  };

  const handleDeleteBusinessLinkInput = (index, id) => {
    const newArray = [...businessLinks];
    newArray.splice(index, 1);
    setBusinessLinks(newArray);
    if (id) {
      handleDeleteBusinessLink(id);
    }
  };

  const types = [
    { value: "FITNESS", label: "FITNESS" },
    { value: "SPORT", label: "SPORT" },
    { value: "WELLNESS", label: "WELLNESS" },
    { value: "SPA", label: "SPA" },
  ];

  const tags = [
    "Facebook",
    "YouTube",
    "WhatsApp",
    "Instagram",
    "TikTok",
    "WeChat",
    "Telegram",
    "Snapchat",
    "Reddit",
    "Twitter",
    "Website",
  ];

  const formattedtags = tags.map((tag) => {
    return {
      label: tag,
      value: tag,
    };
  });

  return {
    values,
    types,
    tags: formattedtags,
    isDisabled,
    handleChangeInput,
    handleSubmit,
    setIsDisabled,
    businessLinks,
    handleDeleteBusinessLinkInput,
    handleChangeBusinessLink,
    handleAddBusinessLinkInput,
  };
};

export default useBusinessInfo;
